@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./font.css);

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Gilroy';
}

.header-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 01;
  overflow: hidden;
}

.bg-video__content {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.linear-style {
  background-image: url('assets/images/frame.png'), linear-gradient(109.6deg, #00074a, #02316c);
  height: 100%;
  background-repeat: no-repeat;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 100vh;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(73deg, #00074a, #02316c);
  border-radius: 100vh;
}

/* nav {
  position: fixed !important;
  top: 15;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  background-color: white;
  color: #00074a;
  z-index: 30;
} */

.scroll-down nav {
  top: 0;
  transform: translate3d(0, -100%, 0);
}

.scroll-up nav {
  position: fixed !important;
  top: 0;
  background-color: white;
  color: #00074a;
}

.cut-off-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
